import { derived, writable } from 'svelte/store';
import { createSlotTimeLimits, createTimes, getPayload, setContent, isFunction, toLocalDate, identity, max, toSeconds, toISOString, createDuration, sortEventChunks, addDuration, cloneDate, addDay, datesEqual, helperEvent, toEventWithLocalDates, toViewWithLocalDates, task, resourceBackgroundColor, resourceTextColor, bgEvent, createEventClasses, createEventContent, keyEnter, height, ceil, outsideRange, runReposition, setPayload, rect, floor, limitToRange, DAY_IN_SECONDS, eventIntersects, createEventChunk, debounce, btnTextDay, themeView, btnTextWeek, btnTextMonth, viewResources } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, element, insert, action_destroyer, is_function, noop, detach, component_subscribe, binding_callbacks, ensure_array_like, space, attr, destroy_each, listen, text, set_style, append, transition_in, group_outros, check_outros, transition_out, create_component, mount_component, destroy_component, empty, construct_svelte_component, run_all, update_keyed_each, outro_and_destroy_block, set_store_value } from 'svelte/internal';
import { getContext, onMount, afterUpdate } from 'svelte';

// slotTimeLimits per day
function dayTimeLimits(state) {
    return derived(
        [state.slotMinTime, state.slotMaxTime, state.flexibleSlotTimeLimits, state._viewDates, state._events],
        ([$slotMinTime, $slotMaxTime, $flexibleSlotTimeLimits, $_viewDates, $_events]) => {
            let dayTimeLimits = {};
            for (let date of $_viewDates) {
                dayTimeLimits[date.getTime()] = createSlotTimeLimits(
                    $slotMinTime,
                    $slotMaxTime,
                    $flexibleSlotTimeLimits,
                    [date],
                    $_events
                );
            }

            return dayTimeLimits;
        }
    );
}

function dayTimes(state) {
    return derived(
        [state._viewDates, state.slotDuration, state.slotLabelInterval, state._dayTimeLimits, state._intlSlotLabel],
        ([$_viewDates, $slotDuration, $slotLabelInterval, $_dayTimeLimits, $_intlSlotLabel]) => {
            let dayTimes = {};
            for (let date of $_viewDates) {
                let time = date.getTime();
                dayTimes[time] = time in $_dayTimeLimits
                    ? createTimes(date, $slotDuration, $slotLabelInterval, $_dayTimeLimits[time], $_intlSlotLabel)
                    : [];
            }

            return dayTimes;
        }
    );
}

function nestedResources(state) {
    return derived(state.resources, $resources => $resources.some(resource => getPayload(resource).children.length));
}

/* packages/resource-timeline/src/Label.svelte generated by Svelte v4.2.19 */

function create_fragment$8(ctx) {
	let span;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			span = element("span");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			/*span_binding*/ ctx[7](span);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, span, /*content*/ ctx[1]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 2) setContent_action.update.call(null, /*content*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(span);
			}

			/*span_binding*/ ctx[7](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$8($$self, $$props, $$invalidate) {
	let $resourceLabelDidMount;
	let $resourceLabelContent;
	let { resource } = $$props;
	let { date = undefined } = $$props;
	let { resourceLabelContent, resourceLabelDidMount } = getContext('state');
	component_subscribe($$self, resourceLabelContent, value => $$invalidate(6, $resourceLabelContent = value));
	component_subscribe($$self, resourceLabelDidMount, value => $$invalidate(8, $resourceLabelDidMount = value));
	let el;
	let content;

	onMount(() => {
		if (isFunction($resourceLabelDidMount)) {
			$resourceLabelDidMount({
				resource,
				date: date ? toLocalDate(date) : undefined,
				el
			});
		}
	});

	function span_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(4, resource = $$props.resource);
		if ('date' in $$props) $$invalidate(5, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$resourceLabelContent, resource, date*/ 112) {
			// Content
			if ($resourceLabelContent) {
				$$invalidate(1, content = isFunction($resourceLabelContent)
				? $resourceLabelContent({
						resource,
						date: date ? toLocalDate(date) : undefined
					})
				: $resourceLabelContent);
			} else {
				$$invalidate(1, content = resource.title);
			}
		}
	};

	return [
		el,
		content,
		resourceLabelContent,
		resourceLabelDidMount,
		resource,
		date,
		$resourceLabelContent,
		span_binding
	];
}

class Label extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$8, create_fragment$8, safe_not_equal, { resource: 4, date: 5 });
	}
}

/* packages/resource-timeline/src/Expander.svelte generated by Svelte v4.2.19 */

function get_each_context$5(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

// (30:0) {#each Array(payload.level) as level}
function create_each_block$5(ctx) {
	let span;
	let span_class_value;

	return {
		c() {
			span = element("span");
			attr(span, "class", span_class_value = /*$theme*/ ctx[1].expander);
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 2 && span_class_value !== (span_class_value = /*$theme*/ ctx[1].expander)) {
				attr(span, "class", span_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(span);
			}
		}
	};
}

// (35:4) {#if payload.children.length}
function create_if_block$4(ctx) {
	let button;
	let button_class_value;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*payload*/ ctx[0].expanded) return create_if_block_1$1;
		return create_else_block$1;
	}

	let current_block_type = select_block_type(ctx);
	let if_block = current_block_type(ctx);

	return {
		c() {
			button = element("button");
			if_block.c();
			attr(button, "class", button_class_value = /*$theme*/ ctx[1].button);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			if_block.m(button, null);

			if (!mounted) {
				dispose = listen(button, "click", /*handleClick*/ ctx[3]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (current_block_type !== (current_block_type = select_block_type(ctx))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(button, null);
				}
			}

			if (dirty & /*$theme*/ 2 && button_class_value !== (button_class_value = /*$theme*/ ctx[1].button)) {
				attr(button, "class", button_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(button);
			}

			if_block.d();
			mounted = false;
			dispose();
		}
	};
}

// (37:41) {:else}
function create_else_block$1(ctx) {
	let t;

	return {
		c() {
			t = text("+");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) {
				detach(t);
			}
		}
	};
}

// (37:12) {#if payload.expanded}
function create_if_block_1$1(ctx) {
	let t;

	return {
		c() {
			t = text("−");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) {
				detach(t);
			}
		}
	};
}

function create_fragment$7(ctx) {
	let t;
	let span;
	let span_class_value;
	let each_value = ensure_array_like(Array(/*payload*/ ctx[0].level));
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$5(get_each_context$5(ctx, each_value, i));
	}

	let if_block = /*payload*/ ctx[0].children.length && create_if_block$4(ctx);

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			span = element("span");
			if (if_block) if_block.c();
			attr(span, "class", span_class_value = /*$theme*/ ctx[1].expander);
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, t, anchor);
			insert(target, span, anchor);
			if (if_block) if_block.m(span, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, payload*/ 3) {
				each_value = ensure_array_like(Array(/*payload*/ ctx[0].level));
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$5(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$5(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t.parentNode, t);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*payload*/ ctx[0].children.length) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block$4(ctx);
					if_block.c();
					if_block.m(span, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*$theme*/ 2 && span_class_value !== (span_class_value = /*$theme*/ ctx[1].expander)) {
				attr(span, "class", span_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(t);
				detach(span);
			}

			destroy_each(each_blocks, detaching);
			if (if_block) if_block.d();
		}
	};
}

function instance$7($$self, $$props, $$invalidate) {
	let $theme;
	let { resource } = $$props;
	let { resources, theme } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	let payload = {};

	function handleClick() {
		$$invalidate(0, payload.expanded = !payload.expanded, payload);
		toggle(payload.children, payload.expanded);
		resources.update(identity);
	}

	function toggle(children, expand) {
		for (let child of children) {
			let payload = getPayload(child);
			payload.hidden = !expand;

			if (payload.expanded) {
				toggle(payload.children, expand);
			}
		}
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(4, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*resource*/ 16) {
			$$invalidate(0, payload = getPayload(resource));
		}
	};

	return [payload, $theme, theme, handleClick, resource];
}

class Expander extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$7, create_fragment$7, safe_not_equal, { resource: 4 });
	}
}

/* packages/resource-timeline/src/Sidebar.svelte generated by Svelte v4.2.19 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	return child_ctx;
}

// (21:16) {#if $_nestedResources}
function create_if_block$3(ctx) {
	let expander;
	let current;

	expander = new Expander({
			props: { resource: /*resource*/ ctx[14] }
		});

	return {
		c() {
			create_component(expander.$$.fragment);
		},
		m(target, anchor) {
			mount_component(expander, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const expander_changes = {};
			if (dirty & /*$_viewResources*/ 8) expander_changes.resource = /*resource*/ ctx[14];
			expander.$set(expander_changes);
		},
		i(local) {
			if (current) return;
			transition_in(expander.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(expander.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(expander, detaching);
		}
	};
}

// (19:8) {#each $_viewResources as resource}
function create_each_block$4(ctx) {
	let div;
	let t0;
	let label;
	let t1;
	let div_class_value;
	let current;
	let if_block = /*$_nestedResources*/ ctx[5] && create_if_block$3(ctx);

	label = new Label({
			props: { resource: /*resource*/ ctx[14] }
		});

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			t0 = space();
			create_component(label.$$.fragment);
			t1 = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[1].resource);
			set_style(div, "flex-basis", max(/*$_resHs*/ ctx[4].get(/*resource*/ ctx[14]) ?? 0, 34) + "px");
			attr(div, "role", "rowheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append(div, t0);
			mount_component(label, div, null);
			append(div, t1);
			current = true;
		},
		p(ctx, dirty) {
			if (/*$_nestedResources*/ ctx[5]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*$_nestedResources*/ 32) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$3(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, t0);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const label_changes = {};
			if (dirty & /*$_viewResources*/ 8) label_changes.resource = /*resource*/ ctx[14];
			label.$set(label_changes);

			if (!current || dirty & /*$theme*/ 2 && div_class_value !== (div_class_value = /*$theme*/ ctx[1].resource)) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty & /*$_resHs, $_viewResources*/ 24) {
				set_style(div, "flex-basis", max(/*$_resHs*/ ctx[4].get(/*resource*/ ctx[14]) ?? 0, 34) + "px");
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(label.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(label.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			if (if_block) if_block.d();
			destroy_component(label);
		}
	};
}

function create_fragment$6(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let current;
	let each_value = ensure_array_like(/*$_viewResources*/ ctx[3]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[1].sidebarTitle);
			set_style(div0, "flex-basis", /*titleHeight*/ ctx[0] + "px");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[1].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[1].sidebar);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div1_binding*/ ctx[12](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$theme*/ 2 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[1].sidebarTitle)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*titleHeight*/ 1) {
				set_style(div0, "flex-basis", /*titleHeight*/ ctx[0] + "px");
			}

			if (dirty & /*$theme, $_resHs, $_viewResources, $_nestedResources*/ 58) {
				each_value = ensure_array_like(/*$_viewResources*/ ctx[3]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 2 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[1].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 2 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[1].sidebar)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[12](null);
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let $_headerEl;
	let $theme;
	let $_sidebarEl;
	let $_viewResources;
	let $_resHs;
	let $_nestedResources;
	let { _viewResources, _headerEl, _resHs, _sidebarEl, _nestedResources, theme } = getContext('state');
	component_subscribe($$self, _viewResources, value => $$invalidate(3, $_viewResources = value));
	component_subscribe($$self, _headerEl, value => $$invalidate(13, $_headerEl = value));
	component_subscribe($$self, _resHs, value => $$invalidate(4, $_resHs = value));
	component_subscribe($$self, _sidebarEl, value => $$invalidate(2, $_sidebarEl = value));
	component_subscribe($$self, _nestedResources, value => $$invalidate(5, $_nestedResources = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	let titleHeight = 0;

	afterUpdate(() => {
		$$invalidate(0, titleHeight = $_headerEl.clientHeight);
	});

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_sidebarEl = $$value;
			_sidebarEl.set($_sidebarEl);
		});
	}

	return [
		titleHeight,
		$theme,
		$_sidebarEl,
		$_viewResources,
		$_resHs,
		$_nestedResources,
		_viewResources,
		_headerEl,
		_resHs,
		_sidebarEl,
		_nestedResources,
		theme,
		div1_binding
	];
}

class Sidebar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$6, create_fragment$6, safe_not_equal, {});
	}
}

/* packages/resource-timeline/src/Header.svelte generated by Svelte v4.2.19 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

function get_each_context_1$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i];
	return child_ctx;
}

// (30:16) {:else}
function create_else_block(ctx) {
	let div;
	let time_1;
	let time_1_datetime_value;
	let time_1_aria_label_value;
	let setContent_action;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			time_1 = element("time");
			attr(time_1, "datetime", time_1_datetime_value = toISOString(/*date*/ ctx[15], 10));
			attr(time_1, "aria-label", time_1_aria_label_value = /*$_intlDayHeaderAL*/ ctx[4].format(/*date*/ ctx[15]));
			attr(div, "class", div_class_value = /*$theme*/ ctx[0].time);
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, time_1);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*$_intlDayHeader*/ ctx[5].format(/*date*/ ctx[15])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_viewDates*/ 4 && time_1_datetime_value !== (time_1_datetime_value = toISOString(/*date*/ ctx[15], 10))) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (dirty & /*$_intlDayHeaderAL, $_viewDates*/ 20 && time_1_aria_label_value !== (time_1_aria_label_value = /*$_intlDayHeaderAL*/ ctx[4].format(/*date*/ ctx[15]))) {
				attr(time_1, "aria-label", time_1_aria_label_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_viewDates*/ 36) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[5].format(/*date*/ ctx[15]));

			if (dirty & /*$theme*/ 1 && div_class_value !== (div_class_value = /*$theme*/ ctx[0].time)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (12:16) {#if toSeconds($slotDuration)}
function create_if_block$2(ctx) {
	let div0;
	let time_1;
	let time_1_datetime_value;
	let time_1_aria_label_value;
	let setContent_action;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*$_dayTimes*/ ctx[6][/*date*/ ctx[15].getTime()]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1$2(get_each_context_1$2(ctx, each_value_1, i));
	}

	return {
		c() {
			div0 = element("div");
			time_1 = element("time");
			t = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(time_1, "datetime", time_1_datetime_value = toISOString(/*date*/ ctx[15], 10));
			attr(time_1, "aria-label", time_1_aria_label_value = /*$_intlDayHeaderAL*/ ctx[4].format(/*date*/ ctx[15]));
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].times);
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, time_1);
			insert(target, t, anchor);
			insert(target, div1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*$_intlDayHeader*/ ctx[5].format(/*date*/ ctx[15])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_viewDates*/ 4 && time_1_datetime_value !== (time_1_datetime_value = toISOString(/*date*/ ctx[15], 10))) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (dirty & /*$_intlDayHeaderAL, $_viewDates*/ 20 && time_1_aria_label_value !== (time_1_aria_label_value = /*$_intlDayHeaderAL*/ ctx[4].format(/*date*/ ctx[15]))) {
				attr(time_1, "aria-label", time_1_aria_label_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_viewDates*/ 36) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[5].format(/*date*/ ctx[15]));

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme, $_dayTimes, $_viewDates*/ 69) {
				each_value_1 = ensure_array_like(/*$_dayTimes*/ ctx[6][/*date*/ ctx[15].getTime()]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1$2(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1$2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].times)) {
				attr(div1, "class", div1_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div0);
				detach(t);
				detach(div1);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

// (21:24) {#each $_dayTimes[date.getTime()] as time}
function create_each_block_1$2(ctx) {
	let div;
	let time_1;
	let time_1_datetime_value;
	let setContent_action;
	let t;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			time_1 = element("time");
			t = space();
			attr(time_1, "datetime", time_1_datetime_value = /*time*/ ctx[18][0]);
			attr(div, "class", div_class_value = /*$theme*/ ctx[0].time);
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, time_1);
			append(div, t);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*time*/ ctx[18][1]));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_dayTimes, $_viewDates*/ 68 && time_1_datetime_value !== (time_1_datetime_value = /*time*/ ctx[18][0])) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_dayTimes, $_viewDates*/ 68) setContent_action.update.call(null, /*time*/ ctx[18][1]);

			if (dirty & /*$theme*/ 1 && div_class_value !== (div_class_value = /*$theme*/ ctx[0].time)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (10:8) {#each $_viewDates as date}
function create_each_block$3(ctx) {
	let div;
	let show_if;
	let t;
	let div_class_value;

	function select_block_type(ctx, dirty) {
		if (dirty & /*$slotDuration*/ 8) show_if = null;
		if (show_if == null) show_if = !!toSeconds(/*$slotDuration*/ ctx[3]);
		if (show_if) return create_if_block$2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div = element("div");
			if_block.c();
			t = space();
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[15].getUTCDay()]));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if_block.m(div, null);
			append(div, t);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, t);
				}
			}

			if (dirty & /*$theme, $_viewDates*/ 5 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[15].getUTCDay()]))) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			if_block.d();
		}
	};
}

function create_fragment$5(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$3(get_each_context$3(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].days);
			attr(div0, "role", "row");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].header);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div2, t);
			append(div2, div1);
			/*div2_binding*/ ctx[14](div2);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, $_viewDates, $_dayTimes, $_intlDayHeaderAL, $_intlDayHeader, $slotDuration*/ 125) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$3(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].days)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].header)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
			/*div2_binding*/ ctx[14](null);
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $theme;
	let $_headerEl;
	let $_viewDates;
	let $slotDuration;
	let $_intlDayHeaderAL;
	let $_intlDayHeader;
	let $_dayTimes;
	let { _headerEl, _intlDayHeader, _intlDayHeaderAL, _dayTimes, _viewDates, slotDuration, theme } = getContext('state');
	component_subscribe($$self, _headerEl, value => $$invalidate(1, $_headerEl = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(5, $_intlDayHeader = value));
	component_subscribe($$self, _intlDayHeaderAL, value => $$invalidate(4, $_intlDayHeaderAL = value));
	component_subscribe($$self, _dayTimes, value => $$invalidate(6, $_dayTimes = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(2, $_viewDates = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(3, $slotDuration = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_headerEl = $$value;
			_headerEl.set($_headerEl);
		});
	}

	return [
		$theme,
		$_headerEl,
		$_viewDates,
		$slotDuration,
		$_intlDayHeaderAL,
		$_intlDayHeader,
		$_dayTimes,
		_headerEl,
		_intlDayHeader,
		_intlDayHeaderAL,
		_dayTimes,
		_viewDates,
		slotDuration,
		theme,
		div2_binding
	];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$5, safe_not_equal, {});
	}
}

function prepareEventChunks(chunks, $_viewDates, $_dayTimeLimits, $slotDuration) {
    let longChunks = {};
    let filteredChunks = [];

    if (chunks.length) {
        sortEventChunks(chunks);

        let step = toSeconds($slotDuration);
        let prevChunk;
        for (let chunk of chunks) {
            let prevDayEnd;
            if (step) {
                let slots = 0;
                for (let i = 0; i < $_viewDates.length; ++i) {
                    let slotTimeLimits = getSlotTimeLimits($_dayTimeLimits, $_viewDates[i]);
                    let dayStart = addDuration(cloneDate($_viewDates[i]), slotTimeLimits.min);
                    let dayEnd = addDuration(cloneDate($_viewDates[i]), slotTimeLimits.max);
                    if (!chunk.date) {
                        if (chunk.start < dayEnd && chunk.end > dayStart) {
                            // The first day is found
                            chunk.date = $_viewDates[i];
                            if (chunk.start < dayStart) {
                                // Adjust chunk start
                                chunk.start = dayStart;
                            }
                            // Calculate offset
                            chunk.offset = (chunk.start - dayStart) / 1000 / step;
                            // Calculate slots
                            if (chunk.end > dayEnd) {
                                slots += dayEnd - chunk.start;
                            } else {
                                slots += chunk.end - chunk.start || step * 1000;
                                break;
                            }
                        }
                    } else {
                        if (chunk.end <= dayStart) {
                            // Adjust chunk end
                            chunk.end = prevDayEnd;
                            break;
                        }
                        // The chunk is long one
                        let key = $_viewDates[i].getTime();
                        if (longChunks[key]) {
                            longChunks[key].push(chunk);
                        } else {
                            longChunks[key] = [chunk];
                        }
                        // Calculate slots
                        if (chunk.end > dayEnd) {
                            slots += dayEnd - dayStart;
                        } else {
                            slots += chunk.end - dayStart;
                            break;
                        }
                    }
                    prevDayEnd = dayEnd;
                }
                chunk.slots = slots / 1000 / step;
            } else {
                // Month view
                let days = 0;
                for (let i = 0; i < $_viewDates.length; ++i) {
                    let dayStart = $_viewDates[i];
                    let dayEnd = addDay(cloneDate(dayStart));
                    if (!chunk.date) {
                        if (chunk.start < dayEnd) {
                            // The first day is found
                            chunk.date = dayStart;
                            if (chunk.start < dayStart) {
                                // Adjust chunk start
                                chunk.start = dayStart;
                            }
                            ++days;
                        }
                    } else {
                        if (chunk.end <= dayStart) {
                            // Adjust chunk end
                            chunk.end = prevDayEnd;
                            break;
                        }
                        // The chunk is long one
                        let key = dayStart.getTime();
                        if (longChunks[key]) {
                            longChunks[key].push(chunk);
                        } else {
                            longChunks[key] = [chunk];
                        }
                        ++days;
                    }
                    prevDayEnd = dayEnd;
                }
                chunk.days = days;
            }

            if (!chunk.date) {
                // Chunk is outside the slot time limits, so skip it
                continue;
            }

            if (prevChunk && datesEqual(prevChunk.date, chunk.date)) {
                chunk.prev = prevChunk;
            }
            prevChunk = chunk;
            filteredChunks.push(chunk);
        }
    }

    return [filteredChunks, longChunks];
}

function repositionEvent(chunk, dayChunks, longChunks, height, allDay) {
    chunk.top = 0;
    chunk.bottom = height;
    let margin = 1;
    let key = chunk.date.getTime();
    longChunks = longChunks?.[key] ?? [];
    let chunks = [...dayChunks, ...longChunks];
    chunks.sort((a, b) => (a.top ?? 0) - (b.top ?? 0) || a.start - b.start || b.event.allDay - a.event.allDay);
    for (let dayChunk of chunks) {
        if (dayChunk === chunk) {
            continue;
        }
        if ((allDay || chunk.start < dayChunk.end && chunk.end > dayChunk.start) && chunk.top < dayChunk.bottom && chunk.bottom > dayChunk.top) {
            let offset = dayChunk.bottom - chunk.top + 1;
            margin += offset;
            chunk.top += offset;
            chunk.bottom += offset;
        }
    }

    return margin;
}

function getSlotTimeLimits($_dayTimeLimits, date) {
    return $_dayTimeLimits[date.getTime()] ?? {min: createDuration(0), max: createDuration(0)};
}

/* packages/resource-timeline/src/Event.svelte generated by Svelte v4.2.19 */

function create_if_block$1(ctx) {
	let article;
	let switch_instance0;
	let t0;
	let div;
	let div_class_value;
	let setContent_action;
	let t1;
	let switch_instance1;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[11].resizer;

	function switch_props(ctx, dirty) {
		return {
			props: { start: true, event: /*event*/ ctx[0] }
		};
	}

	if (switch_value) {
		switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance0.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'start']))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'start']).apply(this, arguments);
		});
	}

	var switch_value_1 = /*$_interaction*/ ctx[11].resizer;

	function switch_props_1(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value_1) {
		switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

		switch_instance1.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'end']))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'end']).apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			if (switch_instance0) create_component(switch_instance0.$$.fragment);
			t0 = space();
			div = element("div");
			t1 = space();
			if (switch_instance1) create_component(switch_instance1.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[3].eventBody);
			attr(article, "class", /*classes*/ ctx[5]);
			attr(article, "style", /*style*/ ctx[6]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[8] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[8] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			if (switch_instance0) mount_component(switch_instance0, article, null);
			append(article, t0);
			append(article, div);
			append(article, t1);
			if (switch_instance1) mount_component(switch_instance1, article, null);
			/*article_binding*/ ctx[51](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[7])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[8])) /*onclick*/ ctx[8].apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[8] && keyEnter(/*onclick*/ ctx[8]))) (/*onclick*/ ctx[8] && keyEnter(/*onclick*/ ctx[8])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[31](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[31](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[31](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]))) /*createHandler*/ ctx[31](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11]))) (!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$_interaction*/ 2048 && switch_value !== (switch_value = /*$_interaction*/ ctx[11].resizer)) {
				if (switch_instance0) {
					group_outros();
					const old_component = switch_instance0;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance0 = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance0.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'start']))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'start']).apply(this, arguments);
					});

					create_component(switch_instance0.$$.fragment);
					transition_in(switch_instance0.$$.fragment, 1);
					mount_component(switch_instance0, article, t0);
				} else {
					switch_instance0 = null;
				}
			} else if (switch_value) {
				const switch_instance0_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance0_changes.event = /*event*/ ctx[0];
				switch_instance0.$set(switch_instance0_changes);
			}

			if (!current || dirty[0] & /*$theme*/ 8 && div_class_value !== (div_class_value = /*$theme*/ ctx[3].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 128) setContent_action.update.call(null, /*content*/ ctx[7]);

			if (dirty[0] & /*$_interaction*/ 2048 && switch_value_1 !== (switch_value_1 = /*$_interaction*/ ctx[11].resizer)) {
				if (switch_instance1) {
					group_outros();
					const old_component = switch_instance1;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value_1) {
					switch_instance1 = construct_svelte_component(switch_value_1, switch_props_1(ctx));

					switch_instance1.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'end']))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[11], ['x', 'end']).apply(this, arguments);
					});

					create_component(switch_instance1.$$.fragment);
					transition_in(switch_instance1.$$.fragment, 1);
					mount_component(switch_instance1, article, null);
				} else {
					switch_instance1 = null;
				}
			} else if (switch_value_1) {
				const switch_instance1_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance1_changes.event = /*event*/ ctx[0];
				switch_instance1.$set(switch_instance1_changes);
			}

			if (!current || dirty[0] & /*classes*/ 32) {
				attr(article, "class", /*classes*/ ctx[5]);
			}

			if (!current || dirty[0] & /*style*/ 64) {
				attr(article, "style", /*style*/ ctx[6]);
			}

			if (!current || dirty[0] & /*onclick*/ 256 && article_role_value !== (article_role_value = /*onclick*/ ctx[8] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 256 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[8] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance0) transition_in(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_in(switch_instance1.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance0) transition_out(switch_instance0.$$.fragment, local);
			if (switch_instance1) transition_out(switch_instance1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance0) destroy_component(switch_instance0);
			if (switch_instance1) destroy_component(switch_instance1);
			/*article_binding*/ ctx[51](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment$4(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*width*/ ctx[2] > 0 && create_if_block$1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*width*/ ctx[2] > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*width*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if (if_block) if_block.d(detaching);
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $slotDuration;
	let $eventClick;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $eventTextColor;
	let $resources;
	let $eventColor;
	let $eventBackgroundColor;
	let $slotWidth;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_interaction;
	let { chunk } = $$props;
	let { dayChunks = [] } = $$props;
	let { longChunks = {} } = $$props;
	let { resource = undefined } = $$props;
	let { displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventColor, eventContent, eventClick, eventDidMount, eventClassNames, eventMouseEnter, eventMouseLeave, resources, slotDuration, slotWidth, theme, _view, _intlEventTime, _interaction, _iClasses, _tasks } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(43, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(54, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(49, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(46, $eventTextColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(48, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(42, $eventContent = value));
	component_subscribe($$self, eventClick, value => $$invalidate(39, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(55, $eventDidMount = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(44, $eventClassNames = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(9, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(10, $eventMouseLeave = value));
	component_subscribe($$self, resources, value => $$invalidate(47, $resources = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(53, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(50, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(40, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(41, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(11, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(45, $_iClasses = value));
	let el;
	let event;
	let display;
	let classes;
	let style;
	let content;
	let timeText;
	let onclick;
	let margin = helperEvent(chunk.event.display) ? 1 : 0;
	let width = 0;

	onMount(() => {
		if (isFunction($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (isFunction($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && isFunction(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => interaction.action.drag(event, jsEvent, resize, undefined, [margin, resource], chunk.zeroDuration)
		: undefined;
	}

	function reposition() {
		if (!el) {
			return 0;
		}

		let h = height(el);
		$$invalidate(38, margin = repositionEvent(chunk, dayChunks, longChunks, h, !toSeconds($slotDuration)));
		return margin + h;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(4, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(33, chunk = $$props.chunk);
		if ('dayChunks' in $$props) $$invalidate(34, dayChunks = $$props.dayChunks);
		if ('longChunks' in $$props) $$invalidate(35, longChunks = $$props.longChunks);
		if ('resource' in $$props) $$invalidate(36, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 4) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, width, style, display, $theme*/ 79 | $$self.$$.dirty[1] & /*chunk, $slotWidth, $resources, $eventBackgroundColor, $eventColor, $eventTextColor, margin, resource, $_iClasses, $eventClassNames, $_view*/ 1041060) {
			{
				$$invalidate(1, display = event.display);

				// Style
				if ('slots' in chunk) {
					let left = chunk.offset * $slotWidth;
					$$invalidate(2, width = chunk.slots * $slotWidth);
					$$invalidate(6, style = `left:${left}px;` + `width:${width}px;`);
				} else {
					// Month view
					$$invalidate(2, width = chunk.days * 100);

					$$invalidate(6, style = `width:${width}%;`);
				}

				let bgColor = event.backgroundColor || resourceBackgroundColor(event, $resources) || $eventBackgroundColor || $eventColor;
				let txtColor = event.textColor || resourceTextColor(event, $resources) || $eventTextColor;
				let marginTop = margin;

				if (event._margin) {
					// Force margin for helper events
					let [_margin, _resource] = event._margin;

					if (resource === _resource) {
						marginTop = _margin;
					}
				}

				$$invalidate(6, style += `margin-top:${marginTop}px;`);

				if (bgColor) {
					$$invalidate(6, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(6, style += `color:${txtColor};`);
				}

				$$invalidate(6, style += event.styles.join(';'));

				// Class
				$$invalidate(5, classes = [
					bgEvent(display) ? $theme.bgEvent : $theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 8 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 7684) {
			// Content
			$$invalidate(7, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 256) {
			// Onclick handler
			$$invalidate(8, onclick = !bgEvent(display) && createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		width,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_interaction,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventColor,
		eventContent,
		eventClick,
		eventDidMount,
		eventClassNames,
		eventMouseEnter,
		eventMouseLeave,
		resources,
		slotDuration,
		slotWidth,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		createHandler,
		createDragHandler,
		chunk,
		dayChunks,
		longChunks,
		resource,
		reposition,
		margin,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$eventTextColor,
		$resources,
		$eventColor,
		$eventBackgroundColor,
		$slotWidth,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$4,
			create_fragment$4,
			safe_not_equal,
			{
				chunk: 33,
				dayChunks: 34,
				longChunks: 35,
				resource: 36,
				reposition: 37
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[37];
	}
}

/* packages/resource-timeline/src/Day.svelte generated by Svelte v4.2.19 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[37] = list[i];
	child_ctx[38] = list;
	child_ctx[39] = i;
	return child_ctx;
}

function get_each_context_1$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[37] = list[i];
	return child_ctx;
}

// (77:8) {#if !disabled}
function create_if_block(ctx) {
	let each_blocks_1 = [];
	let each0_lookup = new Map();
	let t0;
	let show_if_1 = /*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]] && /*chunkIntersects*/ ctx[25](/*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]]);
	let t1;
	let each_blocks = [];
	let each1_lookup = new Map();
	let t2;
	let show_if = /*iChunks*/ ctx[3][0] && /*chunkIntersects*/ ctx[25](/*iChunks*/ ctx[3][0]);
	let if_block1_anchor;
	let current;
	let each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[8]);
	const get_key = ctx => /*chunk*/ ctx[37].event;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1$1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each0_lookup.set(key, each_blocks_1[i] = create_each_block_1$1(key, child_ctx));
	}

	let if_block0 = show_if_1 && create_if_block_2(ctx);
	let each_value = ensure_array_like(/*dayChunks*/ ctx[7]);
	const get_key_1 = ctx => /*chunk*/ ctx[37].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$2(ctx, each_value, i);
		let key = get_key_1(child_ctx);
		each1_lookup.set(key, each_blocks[i] = create_each_block$2(key, child_ctx));
	}

	let if_block1 = show_if && create_if_block_1(ctx);

	return {
		c() {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(target, anchor);
				}
			}

			insert(target, t0, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, t2, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty[0] & /*dayBgChunks*/ 256) {
				each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[8]);
				group_outros();
				each_blocks_1 = update_keyed_each(each_blocks_1, dirty, get_key, 1, ctx, each_value_1, each0_lookup, t0.parentNode, outro_and_destroy_block, create_each_block_1$1, t0, get_each_context_1$1);
				check_outros();
			}

			if (dirty[0] & /*iChunks, pointerIdx*/ 16392) show_if_1 = /*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]] && /*chunkIntersects*/ ctx[25](/*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]]);

			if (show_if_1) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks, pointerIdx*/ 16392) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t1.parentNode, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*dayChunks, longChunks, resource, refs*/ 4230) {
				each_value = ensure_array_like(/*dayChunks*/ ctx[7]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key_1, 1, ctx, each_value, each1_lookup, t2.parentNode, outro_and_destroy_block, create_each_block$2, t2, get_each_context$2);
				check_outros();
			}

			if (dirty[0] & /*iChunks*/ 8) show_if = /*iChunks*/ ctx[3][0] && /*chunkIntersects*/ ctx[25](/*iChunks*/ ctx[3][0]);

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks*/ 8) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			transition_in(if_block0);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block1);
			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			transition_out(if_block0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(t0);
				detach(t1);
				detach(t2);
				detach(if_block1_anchor);
			}

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].d(detaching);
			}

			if (if_block0) if_block0.d(detaching);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			if (if_block1) if_block1.d(detaching);
		}
	};
}

// (78:12) {#each dayBgChunks as chunk (chunk.event)}
function create_each_block_1$1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[37] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty[0] & /*dayBgChunks*/ 256) event_changes.chunk = /*chunk*/ ctx[37];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (82:12) {#if iChunks[pointerIdx] && chunkIntersects(iChunks[pointerIdx])}
function create_if_block_2(ctx) {
	let event;
	let current;

	event = new Event({
			props: {
				chunk: /*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks, pointerIdx*/ 16392) event_changes.chunk = /*iChunks*/ ctx[3][/*pointerIdx*/ ctx[14]];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

// (85:12) {#each dayChunks as chunk, i (chunk.event)}
function create_each_block$2(key_1, ctx) {
	let first;
	let event;
	let i = /*i*/ ctx[39];
	let current;
	const assign_event = () => /*event_binding*/ ctx[33](event, i);
	const unassign_event = () => /*event_binding*/ ctx[33](null, i);

	let event_props = {
		chunk: /*chunk*/ ctx[37],
		dayChunks: /*dayChunks*/ ctx[7],
		longChunks: /*longChunks*/ ctx[2],
		resource: /*resource*/ ctx[1]
	};

	event = new Event({ props: event_props });
	assign_event();

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (i !== /*i*/ ctx[39]) {
				unassign_event();
				i = /*i*/ ctx[39];
				assign_event();
			}

			const event_changes = {};
			if (dirty[0] & /*dayChunks*/ 128) event_changes.chunk = /*chunk*/ ctx[37];
			if (dirty[0] & /*dayChunks*/ 128) event_changes.dayChunks = /*dayChunks*/ ctx[7];
			if (dirty[0] & /*longChunks*/ 4) event_changes.longChunks = /*longChunks*/ ctx[2];
			if (dirty[0] & /*resource*/ 2) event_changes.resource = /*resource*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			unassign_event();
			destroy_component(event, detaching);
		}
	};
}

// (89:12) {#if iChunks[0] && chunkIntersects(iChunks[0])}
function create_if_block_1(ctx) {
	let event;
	let current;

	event = new Event({
			props: {
				chunk: /*iChunks*/ ctx[3][0],
				resource: /*resource*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 8) event_changes.chunk = /*iChunks*/ ctx[3][0];
			if (dirty[0] & /*resource*/ 2) event_changes.resource = /*resource*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

function create_fragment$3(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	let mounted;
	let dispose;
	let if_block = !/*disabled*/ ctx[11] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (if_block) if_block.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[15].events);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[15].day + " " + /*$theme*/ ctx[15].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[9] ? ' ' + /*$theme*/ ctx[15].today : '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[15].highlight
			: '') + (/*disabled*/ ctx[11]
			? ' ' + /*$theme*/ ctx[15].disabled
			: '')));

			set_style(div1, "flex-grow", /*allDay*/ ctx[5]
			? null
			: ceil((toSeconds(/*slotTimeLimits*/ ctx[13].max) - toSeconds(/*slotTimeLimits*/ ctx[13].min)) / toSeconds(/*$slotDuration*/ ctx[6])));

			attr(div1, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			if (if_block) if_block.m(div0, null);
			/*div1_binding*/ ctx[34](div1);
			current = true;

			if (!mounted) {
				dispose = listen(div1, "pointerdown", function () {
					if (is_function(/*$_interaction*/ ctx[16].action?.select)) /*$_interaction*/ ctx[16].action?.select.apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!/*disabled*/ ctx[11]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*disabled*/ 2048) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div0, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 32768 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[15].events)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty[0] & /*$theme, date, isToday, highlight, disabled*/ 36353 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[15].day + " " + /*$theme*/ ctx[15].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[9] ? ' ' + /*$theme*/ ctx[15].today : '') + (/*highlight*/ ctx[10]
			? ' ' + /*$theme*/ ctx[15].highlight
			: '') + (/*disabled*/ ctx[11]
			? ' ' + /*$theme*/ ctx[15].disabled
			: '')))) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*allDay, slotTimeLimits, $slotDuration*/ 8288) {
				set_style(div1, "flex-grow", /*allDay*/ ctx[5]
				? null
				: ceil((toSeconds(/*slotTimeLimits*/ ctx[13].max) - toSeconds(/*slotTimeLimits*/ ctx[13].min)) / toSeconds(/*$slotDuration*/ ctx[6])));
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			if (if_block) if_block.d();
			/*div1_binding*/ ctx[34](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $slotWidth;
	let $slotDuration;
	let $_dayTimeLimits;
	let $validRange;
	let $highlightedDates;
	let $_today;
	let $theme;
	let $_interaction;
	let { date } = $$props;
	let { resource } = $$props;
	let { chunks } = $$props;
	let { bgChunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { highlightedDates, slotDuration, slotWidth, theme, validRange, _interaction, _today, _dayTimeLimits } = getContext('state');
	component_subscribe($$self, highlightedDates, value => $$invalidate(31, $highlightedDates = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(6, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(35, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(15, $theme = value));
	component_subscribe($$self, validRange, value => $$invalidate(30, $validRange = value));
	component_subscribe($$self, _interaction, value => $$invalidate(16, $_interaction = value));
	component_subscribe($$self, _today, value => $$invalidate(32, $_today = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(29, $_dayTimeLimits = value));
	let el;
	let dayChunks, dayBgChunks;
	let isToday, highlight, disabled;
	let refs = [];
	let slotTimeLimits;
	let allDay;
	let pointerIdx = 1;

	function chunkIntersects(chunk) {
		return datesEqual(chunk.date, date);
	}

	function dateFromPoint(x, y) {
		x -= rect(el).left;

		return {
			allDay,
			date: allDay
			? cloneDate(date)
			: addDuration(addDuration(cloneDate(date), slotTimeLimits.min), $slotDuration, floor(x / $slotWidth)),
			resource,
			dayEl: el,
			disabled
		};
	}

	function reposition() {
		return max(...runReposition(refs, dayChunks));
	}

	function event_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(12, refs);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(4, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('resource' in $$props) $$invalidate(1, resource = $$props.resource);
		if ('chunks' in $$props) $$invalidate(26, chunks = $$props.chunks);
		if ('bgChunks' in $$props) $$invalidate(27, bgChunks = $$props.bgChunks);
		if ('longChunks' in $$props) $$invalidate(2, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(3, iChunks = $$props.iChunks);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$_today*/ 2) {
			$$invalidate(9, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$highlightedDates*/ 1) {
			$$invalidate(10, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty[0] & /*date, $validRange*/ 1073741825) {
			$$invalidate(11, disabled = outsideRange(date, $validRange));
		}

		if ($$self.$$.dirty[0] & /*$_dayTimeLimits, date*/ 536870913) {
			$$invalidate(13, slotTimeLimits = getSlotTimeLimits($_dayTimeLimits, date));
		}

		if ($$self.$$.dirty[0] & /*$slotDuration, allDay*/ 96) {
			{
				$$invalidate(5, allDay = !toSeconds($slotDuration));
				$$invalidate(14, pointerIdx = allDay ? 2 : 1);
			}
		}

		if ($$self.$$.dirty[0] & /*chunks*/ 67108864) {
			$$invalidate(7, dayChunks = chunks.filter(chunkIntersects));
		}

		if ($$self.$$.dirty[0] & /*bgChunks, allDay*/ 134217760) {
			$$invalidate(8, dayBgChunks = bgChunks.filter(bgChunk => (!allDay || bgChunk.event.allDay) && chunkIntersects(bgChunk)));
		}

		if ($$self.$$.dirty[0] & /*el*/ 16) {
			if (el) {
				setPayload(el, dateFromPoint);
			}
		}
	};

	return [
		date,
		resource,
		longChunks,
		iChunks,
		el,
		allDay,
		$slotDuration,
		dayChunks,
		dayBgChunks,
		isToday,
		highlight,
		disabled,
		refs,
		slotTimeLimits,
		pointerIdx,
		$theme,
		$_interaction,
		highlightedDates,
		slotDuration,
		slotWidth,
		theme,
		validRange,
		_interaction,
		_today,
		_dayTimeLimits,
		chunkIntersects,
		chunks,
		bgChunks,
		reposition,
		$_dayTimeLimits,
		$validRange,
		$highlightedDates,
		$_today,
		event_binding,
		div1_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				date: 0,
				resource: 1,
				chunks: 26,
				bgChunks: 27,
				longChunks: 2,
				iChunks: 3,
				reposition: 28
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[28];
	}
}

/* packages/resource-timeline/src/Days.svelte generated by Svelte v4.2.19 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	child_ctx[30] = list;
	child_ctx[31] = i;
	return child_ctx;
}

// (73:4) {#each $_viewDates as date, i}
function create_each_block$1(ctx) {
	let day;
	let i = /*i*/ ctx[31];
	let current;
	const assign_day = () => /*day_binding*/ ctx[25](day, i);
	const unassign_day = () => /*day_binding*/ ctx[25](null, i);

	let day_props = {
		date: /*date*/ ctx[29],
		resource: /*resource*/ ctx[0],
		chunks: /*chunks*/ ctx[1],
		bgChunks: /*bgChunks*/ ctx[2],
		longChunks: /*longChunks*/ ctx[4],
		iChunks: /*iChunks*/ ctx[5]
	};

	day = new Day({ props: day_props });
	assign_day();

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[31]) {
				unassign_day();
				i = /*i*/ ctx[31];
				assign_day();
			}

			const day_changes = {};
			if (dirty[0] & /*$_viewDates*/ 8) day_changes.date = /*date*/ ctx[29];
			if (dirty[0] & /*resource*/ 1) day_changes.resource = /*resource*/ ctx[0];
			if (dirty[0] & /*chunks*/ 2) day_changes.chunks = /*chunks*/ ctx[1];
			if (dirty[0] & /*bgChunks*/ 4) day_changes.bgChunks = /*bgChunks*/ ctx[2];
			if (dirty[0] & /*longChunks*/ 16) day_changes.longChunks = /*longChunks*/ ctx[4];
			if (dirty[0] & /*iChunks*/ 32) day_changes.iChunks = /*iChunks*/ ctx[5];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_day();
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let div;
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[3]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[8].days);
			set_style(div, "flex-basis", max(/*height*/ ctx[7], 34) + "px");
			attr(div, "role", "row");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*reposition*/ ctx[17]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_viewDates, resource, chunks, bgChunks, longChunks, iChunks, refs*/ 127) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[3]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 256 && div_class_value !== (div_class_value = /*$theme*/ ctx[8].days)) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty[0] & /*height*/ 128) {
				set_style(div, "flex-basis", max(/*height*/ ctx[7], 34) + "px");
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $slotDuration;
	let $_dayTimeLimits;
	let $_viewDates;
	let $_iEvents;
	let $_events;
	let $_resHs;
	let $validRange;
	let $theme;
	let { resource } = $$props;
	let { _viewDates, _events, _iEvents, _queue2, _resHs, _dayTimeLimits, slotDuration, theme, validRange } = getContext('state');
	component_subscribe($$self, _viewDates, value => $$invalidate(3, $_viewDates = value));
	component_subscribe($$self, _events, value => $$invalidate(23, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(22, $_iEvents = value));
	component_subscribe($$self, _resHs, value => $$invalidate(26, $_resHs = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(21, $_dayTimeLimits = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(20, $slotDuration = value));
	component_subscribe($$self, theme, value => $$invalidate(8, $theme = value));
	component_subscribe($$self, validRange, value => $$invalidate(24, $validRange = value));
	let chunks, bgChunks, longChunks, iChunks = [];
	let start;
	let end;
	let refs = [];
	let height = 0;
	let debounceHandle = {};

	function reposition() {
		debounce(
			() => {
				$$invalidate(7, height = ceil(max(...runReposition(refs, $_viewDates))) + 10);
				$_resHs.set(resource, height);
				_resHs.set($_resHs);
			},
			debounceHandle,
			_queue2
		);
	}

	function day_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(6, refs);
		});
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(0, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$_viewDates, $validRange, $_dayTimeLimits, start, end*/ 19660808) {
			{
				$$invalidate(18, start = cloneDate(limitToRange($_viewDates[0], $validRange)));
				$$invalidate(19, end = cloneDate(limitToRange($_viewDates.at(-1), $validRange)));
				let slotTimeLimits = getSlotTimeLimits($_dayTimeLimits, start);
				addDuration(start, slotTimeLimits.min);
				slotTimeLimits = getSlotTimeLimits($_dayTimeLimits, end);

				slotTimeLimits.max.seconds > DAY_IN_SECONDS
				? addDuration(end, slotTimeLimits.max)
				: addDay(/** @see https://github.com/vkurko/calendar/issues/333 */ end);
			}
		}

		if ($$self.$$.dirty[0] & /*$_events, start, end, resource, bgChunks, chunks, $_viewDates, $_dayTimeLimits, $slotDuration*/ 12320783) {
			{
				$$invalidate(1, chunks = []);
				$$invalidate(2, bgChunks = []);

				for (let event of $_events) {
					if (eventIntersects(event, start, end, resource)) {
						let chunk = createEventChunk(event, start, end);

						if (bgEvent(event.display)) {
							bgChunks.push(chunk);
						} else {
							chunks.push(chunk);
						}
					}
				}

				$$invalidate(2, [bgChunks] = prepareEventChunks(bgChunks, $_viewDates, $_dayTimeLimits, $slotDuration), bgChunks);
				$$invalidate(1, [chunks, longChunks] = prepareEventChunks(chunks, $_viewDates, $_dayTimeLimits, $slotDuration), chunks, (((((((((($$invalidate(4, longChunks), $$invalidate(23, $_events)), $$invalidate(18, start)), $$invalidate(19, end)), $$invalidate(0, resource)), $$invalidate(2, bgChunks)), $$invalidate(1, chunks)), $$invalidate(3, $_viewDates)), $$invalidate(21, $_dayTimeLimits)), $$invalidate(20, $slotDuration)), $$invalidate(24, $validRange)));

				// Run reposition only when events get changed
				reposition();
			}
		}

		if ($$self.$$.dirty[0] & /*$_iEvents, start, end, resource, $_viewDates, $_dayTimeLimits, $slotDuration*/ 8126473) {
			$$invalidate(5, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && eventIntersects(event, start, end, resource)) {
					chunk = createEventChunk(event, start, end);
					[[chunk]] = prepareEventChunks([chunk], $_viewDates, $_dayTimeLimits, $slotDuration);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}
	};

	return [
		resource,
		chunks,
		bgChunks,
		$_viewDates,
		longChunks,
		iChunks,
		refs,
		height,
		$theme,
		_viewDates,
		_events,
		_iEvents,
		_resHs,
		_dayTimeLimits,
		slotDuration,
		theme,
		validRange,
		reposition,
		start,
		end,
		$slotDuration,
		$_dayTimeLimits,
		$_iEvents,
		$_events,
		$validRange,
		day_binding
	];
}

class Days extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, { resource: 0 }, null, [-1, -1]);
	}
}

/* packages/resource-timeline/src/Body.svelte generated by Svelte v4.2.19 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	return child_ctx;
}

// (39:16) {#each $_dayTimes[date.getTime()] as time}
function create_each_block_2(ctx) {
	let div;
	let div_class_value;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].line);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].line)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}
		}
	};
}

// (38:12) {#each $_viewDates as date}
function create_each_block_1(ctx) {
	let each_1_anchor;
	let each_value_2 = ensure_array_like(/*$_dayTimes*/ ctx[3][/*date*/ ctx[29].getTime()]);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_dayTimes, $_viewDates*/ 14) {
				each_value_2 = ensure_array_like(/*$_dayTimes*/ ctx[3][/*date*/ ctx[29].getTime()]);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (44:8) {#each $_viewResources as resource}
function create_each_block(ctx) {
	let days;
	let current;

	days = new Days({
			props: { resource: /*resource*/ ctx[26] }
		});

	return {
		c() {
			create_component(days.$$.fragment);
		},
		m(target, anchor) {
			mount_component(days, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const days_changes = {};
			if (dirty[0] & /*$_viewResources*/ 16) days_changes.resource = /*resource*/ ctx[26];
			days.$set(days_changes);
		},
		i(local) {
			if (current) return;
			transition_in(days.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(days.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(days, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div2;
	let div1;
	let div0;
	let div0_class_value;
	let t;
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = ensure_array_like(/*$_viewResources*/ ctx[4]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[2].lines);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[2].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[2].body);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, div0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(div0, null);
				}
			}

			append(div1, t);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div2_binding*/ ctx[18](div2);
			current = true;

			if (!mounted) {
				dispose = listen(div2, "scroll", /*handleScroll*/ ctx[16]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_dayTimes, $_viewDates, $theme*/ 14) {
				each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(div0, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[2].lines)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*$_viewResources*/ 16) {
				each_value = ensure_array_like(/*$_viewResources*/ ctx[4]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[2].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[2].body)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
			/*div2_binding*/ ctx[18](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $slotWidth;
	let $slotDuration;
	let $scrollTime;
	let $_viewDates;
	let $_dayTimeLimits;
	let $_bodyEl;
	let $_sidebarEl;
	let $_headerEl;
	let $theme;
	let $_dayTimes;
	let $_viewResources;
	let { _bodyEl, _headerEl, _sidebarEl, _dayTimes, _dayTimeLimits, _viewResources, _viewDates, scrollTime, slotDuration, slotWidth, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(22, $_bodyEl = value));
	component_subscribe($$self, _headerEl, value => $$invalidate(24, $_headerEl = value));
	component_subscribe($$self, _sidebarEl, value => $$invalidate(23, $_sidebarEl = value));
	component_subscribe($$self, _dayTimes, value => $$invalidate(3, $_dayTimes = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(21, $_dayTimeLimits = value));
	component_subscribe($$self, _viewResources, value => $$invalidate(4, $_viewResources = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, scrollTime, value => $$invalidate(17, $scrollTime = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(20, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(19, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	let el;

	function handleScroll() {
		set_store_value(_headerEl, $_headerEl.scrollLeft = $_bodyEl.scrollLeft, $_headerEl);
		set_store_value(_sidebarEl, $_sidebarEl.scrollTop = $_bodyEl.scrollTop, $_sidebarEl);
	}

	function scrollToTime() {
		let slotTimeLimits = getSlotTimeLimits($_dayTimeLimits, $_viewDates[0]);
		$$invalidate(0, el.scrollLeft = (toSeconds($scrollTime) - toSeconds(slotTimeLimits.min)) / toSeconds($slotDuration) * $slotWidth, el);
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*el*/ 1) {
			set_store_value(_bodyEl, $_bodyEl = el, $_bodyEl);
		}

		if ($$self.$$.dirty[0] & /*el, $_viewDates, $scrollTime*/ 131075) {
			if (el) {
				scrollToTime();
			}
		}
	};

	return [
		el,
		$_viewDates,
		$theme,
		$_dayTimes,
		$_viewResources,
		_bodyEl,
		_headerEl,
		_sidebarEl,
		_dayTimes,
		_dayTimeLimits,
		_viewResources,
		_viewDates,
		scrollTime,
		slotDuration,
		slotWidth,
		theme,
		handleScroll,
		$scrollTime,
		div2_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, {}, null, [-1, -1]);
	}
}

/* packages/resource-timeline/src/View.svelte generated by Svelte v4.2.19 */

function create_fragment(ctx) {
	let div1;
	let sidebar;
	let t0;
	let div0;
	let header;
	let t1;
	let body;
	let div0_class_value;
	let div1_class_value;
	let current;
	sidebar = new Sidebar({});
	header = new Header({});
	body = new Body({});

	return {
		c() {
			div1 = element("div");
			create_component(sidebar.$$.fragment);
			t0 = space();
			div0 = element("div");
			create_component(header.$$.fragment);
			t1 = space();
			create_component(body.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].main);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].container);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(sidebar, div1, null);
			append(div1, t0);
			append(div1, div0);
			mount_component(header, div0, null);
			append(div0, t1);
			mount_component(body, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].main)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].container)) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(sidebar.$$.fragment, local);
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(sidebar.$$.fragment, local);
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			destroy_component(sidebar);
			destroy_component(header);
			destroy_component(body);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $theme;
	let { theme } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	return [$theme, theme];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.resourceTimelineDay = 'timeline';
		options.buttonText.resourceTimelineWeek = 'timeline';
		options.buttonText.resourceTimelineMonth = 'timeline';
		options.theme.expander = 'ec-expander';
		options.theme.main = 'ec-main';
		options.theme.times = 'ec-times';
		options.theme.container = 'ec-container';
		options.view = 'resourceTimelineWeek';
		options.views.resourceTimelineDay = {
			buttonText: btnTextDay,
			component: View,
			displayEventEnd: false,
			dayHeaderFormat: {weekday: 'long'},
			duration: {days: 1},
			slotDuration: '01:00',
			theme: themeView('ec-timeline ec-resource-day-view'),
			titleFormat: {year: 'numeric', month: 'long', day: 'numeric'}
		};
		options.views.resourceTimelineWeek = {
			buttonText: btnTextWeek,
			component: View,
			displayEventEnd: false,
			duration: {weeks: 1},
			slotDuration: '01:00',
			theme: themeView('ec-timeline ec-resource-week-view')
		};
		options.views.resourceTimelineMonth = {
			buttonText: btnTextMonth,
			component: View,
			displayEventEnd: false,
			dayHeaderFormat: {
				weekday: 'short',
				day: 'numeric'
			},
			duration: {months: 1},
			slotDuration: {days: 1},
			theme: themeView('ec-timeline ec-resource-month-view'),
			titleFormat: {year: 'numeric', month: 'long'}
		};
	},

	createStores(state) {
		if (!('_viewResources' in state)) {
			state._viewResources = viewResources(state);
		}
		state._headerEl = writable(undefined);
		state._dayTimeLimits = dayTimeLimits(state);  // flexible time limits per day
		state._dayTimes = dayTimes(state);
		state._nestedResources = nestedResources(state);
		state._resHs = writable(new Map());  // resource row heights
		state._sidebarEl = writable(undefined);
	}
};

export { index as default };
