import { derived, writable } from 'svelte/store';
import { cloneDate, subtractDay, addDay, setContent, keyEnter, helperEvent, isFunction, toEventWithLocalDates, toViewWithLocalDates, task, resourceBackgroundColor, resourceTextColor, bgEvent, createEventClasses, createEventContent, rect, ancestor, repositionEvent, height, max, toISOString, outsideEvent, datesEqual, assign, createEventChunk, runReposition, setPayload, eventIntersects, prepareEventChunks, debounce, btnTextMonth, themeView, intl } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, ensure_array_like, element, space, attr, insert, append, noop, detach, destroy_each, component_subscribe, action_destroyer, is_function, construct_svelte_component, create_component, mount_component, listen, group_outros, transition_out, destroy_component, check_outros, transition_in, run_all, binding_callbacks, text, stop_propagation, update_keyed_each, outro_and_destroy_block, empty, set_store_value, bubble } from 'svelte/internal';
import { getContext, onMount, afterUpdate, tick } from 'svelte';

function days(state) {
    return derived([state.date, state.firstDay, state.hiddenDays], ([$date, $firstDay, $hiddenDays]) => {
        let days = [];
        let day = cloneDate($date);
        let max = 7;
        // First day of week
        while (day.getUTCDay() !== $firstDay && max) {
            subtractDay(day);
            --max;
        }
        for (let i = 0; i < 7; ++i) {
            if (!$hiddenDays.includes(day.getUTCDay())) {
                days.push(cloneDate(day));
            }
            addDay(day);
        }

        return days;
    });
}

/* packages/day-grid/src/Header.svelte generated by Svelte v4.2.19 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	return child_ctx;
}

// (10:8) {#each $_days as day}
function create_each_block$4(ctx) {
	let div;
	let span;
	let span_aria_label_value;
	let setContent_action;
	let t;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			span = element("span");
			t = space();
			attr(span, "aria-label", span_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*day*/ ctx[8]));
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*day*/ ctx[8].getUTCDay()]));
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span);
			append(div, t);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, span, /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_intlDayHeaderAL, $_days*/ 6 && span_aria_label_value !== (span_aria_label_value = /*$_intlDayHeaderAL*/ ctx[2].format(/*day*/ ctx[8]))) {
				attr(span, "aria-label", span_aria_label_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_days*/ 10) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[3].format(/*day*/ ctx[8]));

			if (dirty & /*$theme, $_days*/ 3 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*day*/ ctx[8].getUTCDay()]))) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

function create_fragment$6(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let each_value = ensure_array_like(/*$_days*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].days);
			attr(div0, "role", "row");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].header);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div2, t);
			append(div2, div1);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, $_days, $_intlDayHeaderAL, $_intlDayHeader*/ 15) {
				each_value = ensure_array_like(/*$_days*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].days)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].header)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $theme;
	let $_days;
	let $_intlDayHeaderAL;
	let $_intlDayHeader;
	let { theme, _intlDayHeader, _intlDayHeaderAL, _days } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(3, $_intlDayHeader = value));
	component_subscribe($$self, _intlDayHeaderAL, value => $$invalidate(2, $_intlDayHeaderAL = value));
	component_subscribe($$self, _days, value => $$invalidate(1, $_days = value));

	return [
		$theme,
		$_days,
		$_intlDayHeaderAL,
		$_intlDayHeader,
		theme,
		_intlDayHeader,
		_intlDayHeaderAL,
		_days
	];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$6, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Event.svelte generated by Svelte v4.2.19 */

function create_fragment$5(ctx) {
	let article;
	let div;
	let div_class_value;
	let setContent_action;
	let t;
	let switch_instance;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[8].resizer;

	function switch_props(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8], 'x'))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8], 'x').apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			div = element("div");
			t = space();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].eventBody);
			attr(article, "class", /*classes*/ ctx[4]);
			attr(article, "style", /*style*/ ctx[5]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div);
			append(article, t);
			if (switch_instance) mount_component(switch_instance, article, null);
			/*article_binding*/ ctx[51](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[6])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[7] || undefined)) (/*onclick*/ ctx[7] || undefined).apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7]))) (/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[31](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[31](/*$eventMouseEnter*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[31](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]))) /*createHandler*/ ctx[31](/*$eventMouseLeave*/ ctx[10], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8]))) (!helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 64) setContent_action.update.call(null, /*content*/ ctx[6]);

			if (dirty[0] & /*$_interaction*/ 256 && switch_value !== (switch_value = /*$_interaction*/ ctx[8].resizer)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8], 'x'))) /*createDragHandler*/ ctx[32](/*$_interaction*/ ctx[8], 'x').apply(this, arguments);
					});

					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, article, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				const switch_instance_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance_changes.event = /*event*/ ctx[0];
				switch_instance.$set(switch_instance_changes);
			}

			if (!current || dirty[0] & /*classes*/ 16) {
				attr(article, "class", /*classes*/ ctx[4]);
			}

			if (!current || dirty[0] & /*style*/ 32) {
				attr(article, "style", /*style*/ ctx[5]);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_role_value !== (article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance) destroy_component(switch_instance);
			/*article_binding*/ ctx[51](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_hiddenEvents;
	let $dayMaxEvents;
	let $_popupDate;
	let $_interaction;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $eventTextColor;
	let $resources;
	let $eventColor;
	let $eventBackgroundColor;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let { chunk } = $$props;
	let { longChunks = {} } = $$props;
	let { inPopup = false } = $$props;
	let { dates = [] } = $$props;
	let { dayMaxEvents, displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventClick, eventColor, eventContent, eventClassNames, eventDidMount, eventMouseEnter, eventMouseLeave, resources, theme, _view, _intlEventTime, _interaction, _iClasses, _hiddenEvents, _popupDate, _tasks } = getContext('state');
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(54, $dayMaxEvents = value));
	component_subscribe($$self, displayEventEnd, value => $$invalidate(44, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(56, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(50, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(47, $eventTextColor = value));
	component_subscribe($$self, eventClick, value => $$invalidate(40, $eventClick = value));
	component_subscribe($$self, eventColor, value => $$invalidate(49, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(43, $eventContent = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(45, $eventClassNames = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(57, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(9, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(10, $eventMouseLeave = value));
	component_subscribe($$self, resources, value => $$invalidate(48, $resources = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(41, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(42, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(8, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(46, $_iClasses = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(53, $_hiddenEvents = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(55, $_popupDate = value));
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;
	let margin = 1;
	let hidden = false;
	let display;
	let onclick;

	onMount(() => {
		if (isFunction($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (isFunction($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && isFunction(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => $_interaction.action.drag(event, jsEvent, resize, inPopup ? $_popupDate : null, [rect(el).top - rect(ancestor(el, 1)).top, dates])
		: undefined;
	}

	function reposition() {
		if (!el) {
			return;
		}

		$$invalidate(38, margin = repositionEvent(chunk, longChunks, height(el)));

		if ($dayMaxEvents === true) {
			hide();
		} else {
			$$invalidate(39, hidden = false);
		}
	}

	function hide() {
		let dayEl = ancestor(el, 2);
		let h = height(dayEl) - height(dayEl.firstElementChild) - footHeight(dayEl);
		$$invalidate(39, hidden = chunk.bottom > h);
		let update = false;

		// Hide or show the event throughout all days
		for (let date of chunk.dates) {
			let hiddenEvents = $_hiddenEvents[date.getTime()];

			if (hiddenEvents) {
				let size = hiddenEvents.size;

				if (hidden) {
					hiddenEvents.add(chunk.event);
				} else {
					hiddenEvents.delete(chunk.event);
				}

				if (size !== hiddenEvents.size) {
					update = true;
				}
			}
		}

		if (update) {
			_hiddenEvents.set($_hiddenEvents);
		}
	}

	function footHeight(dayEl) {
		let h = 0;

		for (let i = 0; i < chunk.days; ++i) {
			h = max(h, height(dayEl.lastElementChild));
			dayEl = dayEl.nextElementSibling;

			if (!dayEl) {
				break;
			}
		}

		return h;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(33, chunk = $$props.chunk);
		if ('longChunks' in $$props) $$invalidate(34, longChunks = $$props.longChunks);
		if ('inPopup' in $$props) $$invalidate(35, inPopup = $$props.inPopup);
		if ('dates' in $$props) $$invalidate(36, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 4) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, display, style, $theme*/ 39 | $$self.$$.dirty[1] & /*$resources, $eventBackgroundColor, $eventColor, $eventTextColor, chunk, margin, hidden, $_iClasses, $eventClassNames, $_view*/ 1033604) {
			{
				$$invalidate(1, display = event.display);

				// Class & Style
				let bgColor = event.backgroundColor || resourceBackgroundColor(event, $resources) || $eventBackgroundColor || $eventColor;

				let txtColor = event.textColor || resourceTextColor(event, $resources) || $eventTextColor;

				if (bgEvent(display)) {
					$$invalidate(5, style = `width:calc(${chunk.days * 100}% + ${chunk.days - 1}px);`);
				} else {
					let marginTop = margin;

					if (event._margin) {
						// Force margin for helper events
						let [_margin, _dates] = event._margin;

						if (chunk.date >= _dates[0] && chunk.date <= _dates.at(-1)) {
							marginTop = _margin;
						}
					}

					$$invalidate(5, style = `width:calc(${chunk.days * 100}% + ${(chunk.days - 1) * 7}px);` + `margin-top:${marginTop}px;`);
				}

				if (bgColor) {
					$$invalidate(5, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(5, style += `color:${txtColor};`);
				}

				if (hidden) {
					$$invalidate(5, style += 'visibility:hidden;');
				}

				$$invalidate(5, style += event.styles.join(';'));

				$$invalidate(4, classes = [
					bgEvent(display) ? $theme.bgEvent : $theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 4 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 15364) {
			// Content
			$$invalidate(6, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 512) {
			// Onclick handler
			$$invalidate(7, onclick = createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$_interaction,
		$eventMouseEnter,
		$eventMouseLeave,
		dayMaxEvents,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventClick,
		eventColor,
		eventContent,
		eventClassNames,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		resources,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		_hiddenEvents,
		_popupDate,
		createHandler,
		createDragHandler,
		chunk,
		longChunks,
		inPopup,
		dates,
		reposition,
		margin,
		hidden,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$eventTextColor,
		$resources,
		$eventColor,
		$eventBackgroundColor,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$4,
			create_fragment$5,
			safe_not_equal,
			{
				chunk: 33,
				longChunks: 34,
				inPopup: 35,
				dates: 36,
				reposition: 37
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[37];
	}
}

/* packages/day-grid/src/Popup.svelte generated by Svelte v4.2.19 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[20] = list[i];
	return child_ctx;
}

// (100:8) {#each $_popupChunks as chunk (chunk.event)}
function create_each_block$3(key_1, ctx) {
	let first;
	let event;
	let current;

	event = new Event({
			props: { chunk: /*chunk*/ ctx[20], inPopup: true }
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*$_popupChunks*/ 1) event_changes.chunk = /*chunk*/ ctx[20];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

function create_fragment$4(ctx) {
	let div2;
	let div0;
	let time;
	let time_datetime_value;
	let setContent_action;
	let t0;
	let a;
	let t1;
	let a_aria_label_value;
	let div0_class_value;
	let t2;
	let div1;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*$_popupChunks*/ ctx[0]);
	const get_key = ctx => /*chunk*/ ctx[20].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$3(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$3(key, child_ctx));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			time = element("time");
			t0 = space();
			a = element("a");
			t1 = text("×");
			t2 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(time, "datetime", time_datetime_value = toISOString(/*$_popupDate*/ ctx[3], 10));
			attr(a, "role", "button");
			attr(a, "tabindex", "0");
			attr(a, "aria-label", a_aria_label_value = /*$buttonText*/ ctx[6].close);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[4].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[4].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[4].popup);
			attr(div2, "style", /*style*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, time);
			append(div0, t0);
			append(div0, a);
			append(a, t1);
			append(div2, t2);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div2_binding*/ ctx[16](div2);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, time, /*$_intlDayPopover*/ ctx[5].format(/*$_popupDate*/ ctx[3]))),
					listen(a, "click", stop_propagation(/*close*/ ctx[13])),
					listen(a, "keydown", keyEnter(/*close*/ ctx[13])),
					action_destroyer(outsideEvent.call(null, div2, 'pointerdown')),
					listen(div2, "pointerdown", stop_propagation(/*pointerdown_handler*/ ctx[15])),
					listen(div2, "pointerdownoutside", /*handlePointerDownOutside*/ ctx[14])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$_popupDate*/ 8 && time_datetime_value !== (time_datetime_value = toISOString(/*$_popupDate*/ ctx[3], 10))) {
				attr(time, "datetime", time_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayPopover, $_popupDate*/ 40) setContent_action.update.call(null, /*$_intlDayPopover*/ ctx[5].format(/*$_popupDate*/ ctx[3]));

			if (!current || dirty & /*$buttonText*/ 64 && a_aria_label_value !== (a_aria_label_value = /*$buttonText*/ ctx[6].close)) {
				attr(a, "aria-label", a_aria_label_value);
			}

			if (!current || dirty & /*$theme*/ 16 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[4].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$_popupChunks*/ 1) {
				each_value = ensure_array_like(/*$_popupChunks*/ ctx[0]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div1, outro_and_destroy_block, create_each_block$3, null, get_each_context$3);
				check_outros();
			}

			if (!current || dirty & /*$theme*/ 16 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[4].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 16 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[4].popup)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty & /*style*/ 4) {
				attr(div2, "style", /*style*/ ctx[2]);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*div2_binding*/ ctx[16](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $_interaction;
	let $_popupDate;
	let $_popupChunks;
	let $theme;
	let $_intlDayPopover;
	let $buttonText;
	let { buttonText, theme, _interaction, _intlDayPopover, _popupDate, _popupChunks } = getContext('state');
	component_subscribe($$self, buttonText, value => $$invalidate(6, $buttonText = value));
	component_subscribe($$self, theme, value => $$invalidate(4, $theme = value));
	component_subscribe($$self, _interaction, value => $$invalidate(17, $_interaction = value));
	component_subscribe($$self, _intlDayPopover, value => $$invalidate(5, $_intlDayPopover = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(3, $_popupDate = value));
	component_subscribe($$self, _popupChunks, value => $$invalidate(0, $_popupChunks = value));
	let el;
	let style = '';

	function position() {
		let dayEl = ancestor(el, 1);
		let bodyEl = ancestor(dayEl, 3);
		let popupRect = rect(el);
		let dayRect = rect(dayEl);
		let bodyRect = rect(bodyEl);
		$$invalidate(2, style = '');
		let left;

		if (popupRect.width >= bodyRect.width) {
			left = bodyRect.left - dayRect.left;
			let right = dayRect.right - bodyRect.right;
			$$invalidate(2, style += `right:${right}px;`);
		} else {
			left = (dayRect.width - popupRect.width) / 2;

			if (dayRect.left + left < bodyRect.left) {
				left = bodyRect.left - dayRect.left;
			} else if (dayRect.left + left + popupRect.width > bodyRect.right) {
				left = bodyRect.right - dayRect.left - popupRect.width;
			}
		}

		$$invalidate(2, style += `left:${left}px;`);
		let top;

		if (popupRect.height >= bodyRect.height) {
			top = bodyRect.top - dayRect.top;
			let bottom = dayRect.bottom - bodyRect.bottom;
			$$invalidate(2, style += `bottom:${bottom}px;`);
		} else {
			top = (dayRect.height - popupRect.height) / 2;

			if (dayRect.top + top < bodyRect.top) {
				top = bodyRect.top - dayRect.top;
			} else if (dayRect.top + top + popupRect.height > bodyRect.bottom) {
				top = bodyRect.bottom - dayRect.top - popupRect.height;
			}
		}

		$$invalidate(2, style += `top:${top}px;`);
	}

	function reposition() {
		// Skip the first call (el is not defined at this time)
		if (el) {
			$$invalidate(2, style = '');

			// Let chunks to update/mount then position the popup
			tick().then(() => {
				if ($_popupChunks.length) {
					position();
				} else {
					close();
				}
			});
		}
	}

	function close(e) {
		set_store_value(_popupDate, $_popupDate = null, $_popupDate);
	}

	function handlePointerDownOutside(e) {
		close();
		$_interaction.action?.noClick();
	}

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_popupChunks*/ 1) {
			if ($_popupChunks) {
				// Fire reposition only on popup chunks change
				reposition();
			}
		}
	};

	return [
		$_popupChunks,
		el,
		style,
		$_popupDate,
		$theme,
		$_intlDayPopover,
		$buttonText,
		buttonText,
		theme,
		_interaction,
		_intlDayPopover,
		_popupDate,
		_popupChunks,
		close,
		handlePointerDownOutside,
		pointerdown_handler,
		div2_binding
	];
}

class Popup extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$3, create_fragment$4, safe_not_equal, {});
	}
}

/* packages/day-grid/src/Day.svelte generated by Svelte v4.2.19 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[44] = list[i];
	child_ctx[45] = list;
	child_ctx[46] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[44] = list[i];
	return child_ctx;
}

// (104:8) {#each dayBgChunks as chunk (chunk.event)}
function create_each_block_1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[44] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty[0] & /*dayBgChunks*/ 256) event_changes.chunk = /*chunk*/ ctx[44];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (109:4) {#if iChunks[2] && datesEqual(iChunks[2].date, date)}
function create_if_block_3(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][2] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[14].events);
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][2];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 16384 && div_class_value !== (div_class_value = /*$theme*/ ctx[14].events)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(event);
		}
	};
}

// (115:4) {#if iChunks[0] && datesEqual(iChunks[0].date, date)}
function create_if_block_2(ctx) {
	let div;
	let event;
	let div_class_value;
	let current;
	event = new Event({ props: { chunk: /*iChunks*/ ctx[2][0] } });

	return {
		c() {
			div = element("div");
			create_component(event.$$.fragment);
			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[14].events + " " + /*$theme*/ ctx[14].preview));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(event, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*iChunks*/ 4) event_changes.chunk = /*iChunks*/ ctx[2][0];
			event.$set(event_changes);

			if (!current || dirty[0] & /*$theme*/ 16384 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[14].events + " " + /*$theme*/ ctx[14].preview))) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(event);
		}
	};
}

// (121:8) {#each dayChunks as chunk, i (chunk.event)}
function create_each_block$2(key_1, ctx) {
	let first;
	let event;
	let i = /*i*/ ctx[46];
	let current;
	const assign_event = () => /*event_binding*/ ctx[38](event, i);
	const unassign_event = () => /*event_binding*/ ctx[38](null, i);

	let event_props = {
		chunk: /*chunk*/ ctx[44],
		longChunks: /*longChunks*/ ctx[1],
		dates: /*dates*/ ctx[3]
	};

	event = new Event({ props: event_props });
	assign_event();

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (i !== /*i*/ ctx[46]) {
				unassign_event();
				i = /*i*/ ctx[46];
				assign_event();
			}

			const event_changes = {};
			if (dirty[0] & /*dayChunks*/ 32) event_changes.chunk = /*chunk*/ ctx[44];
			if (dirty[0] & /*longChunks*/ 2) event_changes.longChunks = /*longChunks*/ ctx[1];
			if (dirty[0] & /*dates*/ 8) event_changes.dates = /*dates*/ ctx[3];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			unassign_event();
			destroy_component(event, detaching);
		}
	};
}

// (125:4) {#if showPopup}
function create_if_block_1(ctx) {
	let popup;
	let current;
	popup = new Popup({});

	return {
		c() {
			create_component(popup.$$.fragment);
		},
		m(target, anchor) {
			mount_component(popup, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(popup.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(popup.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(popup, detaching);
		}
	};
}

// (129:8) {#if hiddenEvents.size}
function create_if_block(ctx) {
	let a;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			a = element("a");
			attr(a, "role", "button");
			attr(a, "tabindex", "0");
			attr(a, "aria-haspopup", "true");
		},
		m(target, anchor) {
			insert(target, a, anchor);

			if (!mounted) {
				dispose = [
					listen(a, "click", stop_propagation(/*showMore*/ ctx[27])),
					listen(a, "keydown", keyEnter(/*showMore*/ ctx[27])),
					listen(a, "pointerdown", stop_propagation(/*pointerdown_handler*/ ctx[37])),
					action_destroyer(setContent_action = setContent.call(null, a, /*moreLink*/ ctx[12]))
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*moreLink*/ 4096) setContent_action.update.call(null, /*moreLink*/ ctx[12]);
		},
		d(detaching) {
			if (detaching) {
				detach(a);
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment$3(ctx) {
	let div3;
	let time;
	let time_class_value;
	let time_datetime_value;
	let setContent_action;
	let t0;
	let div0;
	let each_blocks_1 = [];
	let each0_lookup = new Map();
	let div0_class_value;
	let t1;
	let show_if_1 = /*iChunks*/ ctx[2][2] && datesEqual(/*iChunks*/ ctx[2][2].date, /*date*/ ctx[0]);
	let t2;
	let show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);
	let t3;
	let div1;
	let each_blocks = [];
	let each1_lookup = new Map();
	let div1_class_value;
	let t4;
	let t5;
	let div2;
	let div2_class_value;
	let div3_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[8]);
	const get_key = ctx => /*chunk*/ ctx[44].event;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each0_lookup.set(key, each_blocks_1[i] = create_each_block_1(key, child_ctx));
	}

	let if_block0 = show_if_1 && create_if_block_3(ctx);
	let if_block1 = show_if && create_if_block_2(ctx);
	let each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
	const get_key_1 = ctx => /*chunk*/ ctx[44].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$2(ctx, each_value, i);
		let key = get_key_1(child_ctx);
		each1_lookup.set(key, each_blocks[i] = create_each_block$2(key, child_ctx));
	}

	let if_block2 = /*showPopup*/ ctx[7] && create_if_block_1();
	let if_block3 = /*hiddenEvents*/ ctx[6].size && create_if_block(ctx);

	return {
		c() {
			div3 = element("div");
			time = element("time");
			t0 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t1 = space();
			if (if_block0) if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			t3 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			if (if_block2) if_block2.c();
			t5 = space();
			div2 = element("div");
			if (if_block3) if_block3.c();
			attr(time, "class", time_class_value = /*$theme*/ ctx[14].dayHead);
			attr(time, "datetime", time_datetime_value = toISOString(/*date*/ ctx[0], 10));
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[14].bgEvents);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[14].events);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[14].dayFoot);

			attr(div3, "class", div3_class_value = "" + (/*$theme*/ ctx[14].day + " " + /*$theme*/ ctx[14].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[9] ? ' ' + /*$theme*/ ctx[14].today : '') + (/*otherMonth*/ ctx[10]
			? ' ' + /*$theme*/ ctx[14].otherMonth
			: '') + (/*highlight*/ ctx[11]
			? ' ' + /*$theme*/ ctx[14].highlight
			: '')));

			attr(div3, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, time);
			append(div3, t0);
			append(div3, div0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(div0, null);
				}
			}

			append(div3, t1);
			if (if_block0) if_block0.m(div3, null);
			append(div3, t2);
			if (if_block1) if_block1.m(div3, null);
			append(div3, t3);
			append(div3, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			append(div3, t4);
			if (if_block2) if_block2.m(div3, null);
			append(div3, t5);
			append(div3, div2);
			if (if_block3) if_block3.m(div2, null);
			/*div3_binding*/ ctx[39](div3);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, time, /*$_intlDayCell*/ ctx[16].format(/*date*/ ctx[0]))),
					listen(div3, "pointerleave", function () {
						if (is_function(/*$_interaction*/ ctx[15].pointer?.leave)) /*$_interaction*/ ctx[15].pointer?.leave.apply(this, arguments);
					}),
					listen(div3, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[15].action?.select)) /*$_interaction*/ ctx[15].action?.select.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 16384 && time_class_value !== (time_class_value = /*$theme*/ ctx[14].dayHead)) {
				attr(time, "class", time_class_value);
			}

			if (!current || dirty[0] & /*date*/ 1 && time_datetime_value !== (time_datetime_value = toISOString(/*date*/ ctx[0], 10))) {
				attr(time, "datetime", time_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*$_intlDayCell, date*/ 65537) setContent_action.update.call(null, /*$_intlDayCell*/ ctx[16].format(/*date*/ ctx[0]));

			if (dirty[0] & /*dayBgChunks*/ 256) {
				each_value_1 = ensure_array_like(/*dayBgChunks*/ ctx[8]);
				group_outros();
				each_blocks_1 = update_keyed_each(each_blocks_1, dirty, get_key, 1, ctx, each_value_1, each0_lookup, div0, outro_and_destroy_block, create_each_block_1, null, get_each_context_1);
				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16384 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[14].bgEvents)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*iChunks, date*/ 5) show_if_1 = /*iChunks*/ ctx[2][2] && datesEqual(/*iChunks*/ ctx[2][2].date, /*date*/ ctx[0]);

			if (show_if_1) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div3, t2);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*iChunks, date*/ 5) show_if = /*iChunks*/ ctx[2][0] && datesEqual(/*iChunks*/ ctx[2][0].date, /*date*/ ctx[0]);

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 5) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div3, t3);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*dayChunks, longChunks, dates, refs*/ 8234) {
				each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key_1, 1, ctx, each_value, each1_lookup, div1, outro_and_destroy_block, create_each_block$2, null, get_each_context$2);
				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 16384 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[14].events)) {
				attr(div1, "class", div1_class_value);
			}

			if (/*showPopup*/ ctx[7]) {
				if (if_block2) {
					if (dirty[0] & /*showPopup*/ 128) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_1();
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div3, t5);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*hiddenEvents*/ ctx[6].size) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(div2, null);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (!current || dirty[0] & /*$theme*/ 16384 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[14].dayFoot)) {
				attr(div2, "class", div2_class_value);
			}

			if (!current || dirty[0] & /*$theme, date, isToday, otherMonth, highlight*/ 19969 && div3_class_value !== (div3_class_value = "" + (/*$theme*/ ctx[14].day + " " + /*$theme*/ ctx[14].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[9] ? ' ' + /*$theme*/ ctx[14].today : '') + (/*otherMonth*/ ctx[10]
			? ' ' + /*$theme*/ ctx[14].otherMonth
			: '') + (/*highlight*/ ctx[11]
			? ' ' + /*$theme*/ ctx[14].highlight
			: '')))) {
				attr(div3, "class", div3_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			transition_in(if_block0);
			transition_in(if_block1);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block2);
			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			transition_out(if_block0);
			transition_out(if_block1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div3);
			}

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].d();
			}

			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			/*div3_binding*/ ctx[39](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $_popupChunks;
	let $_popupDate;
	let $moreLinkContent;
	let $_hiddenEvents;
	let $highlightedDates;
	let $currentDate;
	let $_today;
	let $theme;
	let $_interaction;
	let $_intlDayCell;
	let { date } = $$props;
	let { chunks } = $$props;
	let { bgChunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { dates } = $$props;
	let { date: currentDate, dayMaxEvents, highlightedDates, moreLinkContent, theme, _hiddenEvents, _intlDayCell, _popupDate, _popupChunks, _today, _interaction, _queue } = getContext('state');
	component_subscribe($$self, currentDate, value => $$invalidate(35, $currentDate = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(34, $highlightedDates = value));
	component_subscribe($$self, moreLinkContent, value => $$invalidate(32, $moreLinkContent = value));
	component_subscribe($$self, theme, value => $$invalidate(14, $theme = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(33, $_hiddenEvents = value));
	component_subscribe($$self, _intlDayCell, value => $$invalidate(16, $_intlDayCell = value));
	component_subscribe($$self, _popupDate, value => $$invalidate(31, $_popupDate = value));
	component_subscribe($$self, _popupChunks, value => $$invalidate(40, $_popupChunks = value));
	component_subscribe($$self, _today, value => $$invalidate(36, $_today = value));
	component_subscribe($$self, _interaction, value => $$invalidate(15, $_interaction = value));
	let el;
	let dayChunks, dayBgChunks;
	let isToday;
	let otherMonth;
	let highlight;
	let hiddenEvents = new Set(); // hidden events of this day
	let moreLink = '';
	let showPopup;
	let refs = [];

	function showMore() {
		set_store_value(_popupDate, $_popupDate = date, $_popupDate);
	}

	function setPopupChunks() {
		let nextDay = addDay(cloneDate(date));
		let chunks = dayChunks.concat(longChunks[date.getTime()]?.chunks || []);
		set_store_value(_popupChunks, $_popupChunks = chunks.map(chunk => assign({}, chunk, createEventChunk(chunk.event, date, nextDay), { days: 1, dates: [date] })).sort((a, b) => a.top - b.top), $_popupChunks);
	}

	function reposition() {
		runReposition(refs, dayChunks);
	}

	function pointerdown_handler(event) {
		bubble.call(this, $$self, event);
	}

	function event_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(13, refs);
		});
	}

	function div3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(4, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('chunks' in $$props) $$invalidate(28, chunks = $$props.chunks);
		if ('bgChunks' in $$props) $$invalidate(29, bgChunks = $$props.bgChunks);
		if ('longChunks' in $$props) $$invalidate(1, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(2, iChunks = $$props.iChunks);
		if ('dates' in $$props) $$invalidate(3, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*bgChunks, date, hiddenEvents, chunks, dayChunks*/ 805306465) {
			{
				$$invalidate(5, dayChunks = []);
				$$invalidate(8, dayBgChunks = bgChunks.filter(bgChunk => datesEqual(bgChunk.date, date)));
				hiddenEvents.clear();
				(((($$invalidate(6, hiddenEvents), $$invalidate(29, bgChunks)), $$invalidate(0, date)), $$invalidate(28, chunks)), $$invalidate(5, dayChunks));

				for (let chunk of chunks) {
					if (datesEqual(chunk.date, date)) {
						dayChunks.push(chunk);
					} // if ($dayMaxEvents !== false && dayChunks.length > $dayMaxEvents) {
					// 	chunk.hidden = true;
				} // }
			}
		}

		if ($$self.$$.dirty[0] & /*date, hiddenEvents*/ 65) {
			set_store_value(_hiddenEvents, $_hiddenEvents[date.getTime()] = hiddenEvents, $_hiddenEvents);
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$_today*/ 32) {
			$$invalidate(9, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$currentDate, $highlightedDates*/ 24) {
			{
				$$invalidate(10, otherMonth = date.getUTCMonth() !== $currentDate.getUTCMonth());
				$$invalidate(11, highlight = $highlightedDates.some(d => datesEqual(d, date)));
			}
		}

		if ($$self.$$.dirty[0] & /*hiddenEvents*/ 64 | $$self.$$.dirty[1] & /*$_hiddenEvents, $moreLinkContent*/ 6) {
			if ($_hiddenEvents && hiddenEvents.size) {
				// make Svelte update this block on $_hiddenEvents update
				let text = '+' + hiddenEvents.size + ' more';

				if ($moreLinkContent) {
					$$invalidate(12, moreLink = isFunction($moreLinkContent)
					? $moreLinkContent({ num: hiddenEvents.size, text })
					: $moreLinkContent);
				} else {
					$$invalidate(12, moreLink = text);
				}
			}
		}

		if ($$self.$$.dirty[0] & /*date*/ 1 | $$self.$$.dirty[1] & /*$_popupDate*/ 1) {
			$$invalidate(7, showPopup = $_popupDate && datesEqual(date, $_popupDate));
		}

		if ($$self.$$.dirty[0] & /*showPopup, longChunks, dayChunks*/ 162) {
			if (showPopup && longChunks && dayChunks) {
				// Let chunks to reposition then set popup chunks
				tick().then(setPopupChunks);
			}
		}

		if ($$self.$$.dirty[0] & /*el, date*/ 17) {
			// dateFromPoint
			if (el) {
				setPayload(el, () => ({
					allDay: true,
					date,
					resource: undefined,
					dayEl: el
				}));
			}
		}
	};

	return [
		date,
		longChunks,
		iChunks,
		dates,
		el,
		dayChunks,
		hiddenEvents,
		showPopup,
		dayBgChunks,
		isToday,
		otherMonth,
		highlight,
		moreLink,
		refs,
		$theme,
		$_interaction,
		$_intlDayCell,
		currentDate,
		highlightedDates,
		moreLinkContent,
		theme,
		_hiddenEvents,
		_intlDayCell,
		_popupDate,
		_popupChunks,
		_today,
		_interaction,
		showMore,
		chunks,
		bgChunks,
		reposition,
		$_popupDate,
		$moreLinkContent,
		$_hiddenEvents,
		$highlightedDates,
		$currentDate,
		$_today,
		pointerdown_handler,
		event_binding,
		div3_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$2,
			create_fragment$3,
			safe_not_equal,
			{
				date: 0,
				chunks: 28,
				bgChunks: 29,
				longChunks: 1,
				iChunks: 2,
				dates: 3,
				reposition: 30
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[30];
	}
}

/* packages/day-grid/src/Week.svelte generated by Svelte v4.2.19 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	child_ctx[27] = list;
	child_ctx[28] = i;
	return child_ctx;
}

// (67:4) {#each dates as date, i}
function create_each_block$1(ctx) {
	let day;
	let i = /*i*/ ctx[28];
	let current;
	const assign_day = () => /*day_binding*/ ctx[23](day, i);
	const unassign_day = () => /*day_binding*/ ctx[23](null, i);

	let day_props = {
		date: /*date*/ ctx[26],
		chunks: /*chunks*/ ctx[1],
		bgChunks: /*bgChunks*/ ctx[2],
		longChunks: /*longChunks*/ ctx[3],
		iChunks: /*iChunks*/ ctx[4],
		dates: /*dates*/ ctx[0]
	};

	day = new Day({ props: day_props });
	assign_day();

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[28]) {
				unassign_day();
				i = /*i*/ ctx[28];
				assign_day();
			}

			const day_changes = {};
			if (dirty & /*dates*/ 1) day_changes.date = /*date*/ ctx[26];
			if (dirty & /*chunks*/ 2) day_changes.chunks = /*chunks*/ ctx[1];
			if (dirty & /*bgChunks*/ 4) day_changes.bgChunks = /*bgChunks*/ ctx[2];
			if (dirty & /*longChunks*/ 8) day_changes.longChunks = /*longChunks*/ ctx[3];
			if (dirty & /*iChunks*/ 16) day_changes.iChunks = /*iChunks*/ ctx[4];
			if (dirty & /*dates*/ 1) day_changes.dates = /*dates*/ ctx[0];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_day();
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let div;
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*dates*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[6].days);
			attr(div, "role", "row");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*reposition*/ ctx[14]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*dates, chunks, bgChunks, longChunks, iChunks, refs*/ 63) {
				each_value = ensure_array_like(/*dates*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 64 && div_class_value !== (div_class_value = /*$theme*/ ctx[6].days)) {
				attr(div, "class", div_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $_hiddenEvents;
	let $hiddenDays;
	let $_iEvents;
	let $resources;
	let $filterEventsWithResources;
	let $_events;
	let $theme;
	let { dates } = $$props;
	let { _events, _iEvents, _queue2, _hiddenEvents, resources, filterEventsWithResources, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(22, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(19, $_iEvents = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(17, $_hiddenEvents = value));
	component_subscribe($$self, resources, value => $$invalidate(20, $resources = value));
	component_subscribe($$self, filterEventsWithResources, value => $$invalidate(21, $filterEventsWithResources = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(18, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(6, $theme = value));
	let chunks, bgChunks, longChunks, iChunks = [];
	let start;
	let end;
	let refs = [];
	let debounceHandle = {};

	function reposition() {
		debounce(() => runReposition(refs, dates), debounceHandle, _queue2);
	}

	function day_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(5, refs);
		});
	}

	$$self.$$set = $$props => {
		if ('dates' in $$props) $$invalidate(0, dates = $$props.dates);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*dates*/ 1) {
			{
				$$invalidate(15, start = dates[0]);
				$$invalidate(16, end = addDay(cloneDate(dates.at(-1))));
			}
		}

		if ($$self.$$.dirty & /*$_events, start, end, $filterEventsWithResources, $resources, bgChunks, chunks, $hiddenDays*/ 7700486) {
			{
				$$invalidate(1, chunks = []);
				$$invalidate(2, bgChunks = []);

				for (let event of $_events) {
					if (eventIntersects(event, start, end, $filterEventsWithResources ? $resources : undefined)) {
						let chunk = createEventChunk(event, start, end);

						if (bgEvent(event.display)) {
							if (event.allDay) {
								bgChunks.push(chunk);
							}
						} else {
							chunks.push(chunk);
						}
					}
				}

				prepareEventChunks(bgChunks, $hiddenDays);
				$$invalidate(3, longChunks = prepareEventChunks(chunks, $hiddenDays));

				// Run reposition only when events get changed
				reposition();
			}
		}

		if ($$self.$$.dirty & /*$_iEvents, start, end, $hiddenDays*/ 884736) {
			$$invalidate(4, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && eventIntersects(event, start, end)) {
					chunk = createEventChunk(event, start, end);
					prepareEventChunks([chunk], $hiddenDays);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}

		if ($$self.$$.dirty & /*$_hiddenEvents*/ 131072) {
			if ($_hiddenEvents) {
				// Schedule reposition during next update
				tick().then(reposition);
			}
		}
	};

	return [
		dates,
		chunks,
		bgChunks,
		longChunks,
		iChunks,
		refs,
		$theme,
		_events,
		_iEvents,
		_hiddenEvents,
		resources,
		filterEventsWithResources,
		hiddenDays,
		theme,
		reposition,
		start,
		end,
		$_hiddenEvents,
		$hiddenDays,
		$_iEvents,
		$resources,
		$filterEventsWithResources,
		$_events,
		day_binding
	];
}

class Week extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$2, safe_not_equal, { dates: 0 });
	}
}

/* packages/day-grid/src/Body.svelte generated by Svelte v4.2.19 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

// (30:8) {#each weeks as dates}
function create_each_block(ctx) {
	let week;
	let current;
	week = new Week({ props: { dates: /*dates*/ ctx[15] } });

	return {
		c() {
			create_component(week.$$.fragment);
		},
		m(target, anchor) {
			mount_component(week, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const week_changes = {};
			if (dirty & /*weeks*/ 1) week_changes.dates = /*dates*/ ctx[15];
			week.$set(week_changes);
		},
		i(local) {
			if (current) return;
			transition_in(week.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(week.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(week, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	let each_value = ensure_array_like(/*weeks*/ ctx[0]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[2].content);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[2].body + (/*$dayMaxEvents*/ ctx[1] === true
			? ' ' + /*$theme*/ ctx[2].uniform
			: '')));
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			/*div1_binding*/ ctx[13](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*weeks*/ 1) {
				each_value = ensure_array_like(/*weeks*/ ctx[0]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 4 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[2].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme, $dayMaxEvents*/ 6 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[2].body + (/*$dayMaxEvents*/ ctx[1] === true
			? ' ' + /*$theme*/ ctx[2].uniform
			: '')))) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[13](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_viewDates;
	let $dayMaxEvents;
	let $_hiddenEvents;
	let $hiddenDays;
	let $theme;
	let $_bodyEl;
	let { _bodyEl, _viewDates, _hiddenEvents, dayMaxEvents, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(3, $_bodyEl = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(11, $_viewDates = value));
	component_subscribe($$self, _hiddenEvents, value => $$invalidate(14, $_hiddenEvents = value));
	component_subscribe($$self, dayMaxEvents, value => $$invalidate(1, $dayMaxEvents = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(12, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	let weeks;
	let days;

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_bodyEl = $$value;
			_bodyEl.set($_bodyEl);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$hiddenDays, $dayMaxEvents, $_viewDates, days, weeks*/ 7171) {
			{
				$$invalidate(0, weeks = []);
				$$invalidate(10, days = 7 - $hiddenDays.length);
				set_store_value(_hiddenEvents, $_hiddenEvents = {}, $_hiddenEvents);

				for (let i = 0; i < $_viewDates.length / days; ++i) {
					let dates = [];

					for (let j = 0; j < days; ++j) {
						dates.push($_viewDates[i * days + j]);
					}

					weeks.push(dates);
				}
			}
		}
	};

	return [
		weeks,
		$dayMaxEvents,
		$theme,
		$_bodyEl,
		_bodyEl,
		_viewDates,
		_hiddenEvents,
		dayMaxEvents,
		hiddenDays,
		theme,
		days,
		$_viewDates,
		$hiddenDays,
		div1_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment$1, safe_not_equal, {});
	}
}

/* packages/day-grid/src/View.svelte generated by Svelte v4.2.19 */

function create_fragment(ctx) {
	let header;
	let t;
	let body;
	let current;
	header = new Header({});
	body = new Body({});

	return {
		c() {
			create_component(header.$$.fragment);
			t = space();
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(header, target, anchor);
			insert(target, t, anchor);
			mount_component(body, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(t);
			}

			destroy_component(header, detaching);
			destroy_component(body, detaching);
		}
	};
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		options.dayMaxEvents = false;
		options.dayCellFormat = {day: 'numeric'};
		options.dayPopoverFormat = {month: 'long', day: 'numeric', year: 'numeric'};
		options.moreLinkContent = undefined;
		// Common options
		options.buttonText.dayGridMonth = 'month';
		options.buttonText.close = 'Close';
		options.theme.uniform = 'ec-uniform';
		options.theme.dayFoot = 'ec-day-foot';
		options.theme.popup = 'ec-popup';
		options.view = 'dayGridMonth';
		options.views.dayGridMonth = {
			buttonText: btnTextMonth,
			component: View,
			dayHeaderFormat: {weekday: 'short'},
			dayHeaderAriaLabelFormat: {weekday: 'long'},
			displayEventEnd: false,
			duration: {months: 1},
			theme: themeView('ec-day-grid ec-month-view'),
			titleFormat: {year: 'numeric', month: 'long'}
		};
	},

	createStores(state) {
		state._days = days(state);
		state._intlDayCell = intl(state.locale, state.dayCellFormat);
		state._intlDayPopover = intl(state.locale, state.dayPopoverFormat);
		state._hiddenEvents = writable({});
		state._popupDate = writable(null);
		state._popupChunks = writable([]);
	}
};

export { index as default };
